import React from "react";
import 'bootstrap/dist/css/bootstrap.css'
import "./App.scss";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Navigation from "./navigation";
import { BrowserRouter, useLocation, useNavigate } from "react-router-dom";
function App() {
  
  return (
    <>
      <div className="App">
        <React.StrictMode>
          <BrowserRouter>
            <Navigation />
            <ToastContainer />
          </BrowserRouter>
        </React.StrictMode>
      </div>
    </>
  );
}

export default App;
