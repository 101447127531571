import {
  Routes,
  Route,
  RoutesProps,
  useLocation,
  useNavigate,
  matchPath,
} from "react-router-dom";

import React, { Suspense, useEffect } from "react";
import Main from "../components/Main";
import ProtectedRoute from "./ProtectedRoute";

const Navigation = () => {
  interface ProtectedRouteProps extends RoutesProps {
    authenticationPath: string;
  }
  const defaultProtectedRouteProps: Omit<ProtectedRouteProps, "outlet"> = {
    authenticationPath: "/login",
  };

  const Login = React.lazy(() => import("../components/Login/Login"));

  const Dashboardultimate = React.lazy(
    () => import("../components/Ultimate/Ultimate")
  );
  const Dashboard = React.lazy(
    () => import("../components/Dashboard/Dashboard")
  );
  const Dashboardaverage = React.lazy(
    () => import("../components/Average/Average")
  );
  const SelectStation = React.lazy(
    () => import("../components/Login/SelectStation")
  );

  const location = useLocation();
  const navigate = useNavigate();

  // Define the routes you want to protect
  const protectedRoutes = [
    "/login",
    "/dashboard-ultrapro/:site_id/:company_id/:machine_id/:outdoorSensorId",
    "/select-station/:company_id",
    "/dashboard-ultimate/:site_id/:company_id/:machine_id/:outdoorSensorId",
    "/dashboard-vanilla/:site_id/:company_id/:machine_id/:outdoorSensorId",
  ];

  // const isProtectedRoute = protectedRoutes.includes(location.pathname);

  useEffect(() => {
    const isProtectedRoute = protectedRoutes.some(route => {
      const match = matchPath(route, location.pathname);
      return match !== null; 
    });

    if (!isProtectedRoute) {
      navigate("/login");
    }
  }, [location.pathname, navigate]); 

  return (
    <>
      <div id="main-wraper">
        <Routes>
          <Route
            path="/login"
            element={
              <Suspense fallback={<></>}>
                <Login />
              </Suspense>
            }
          />
          <Route
            path="/"
            element={
              <ProtectedRoute
                {...defaultProtectedRouteProps}
                outlet={<Main />}
              />
            }
          >
            <Route
              path="/dashboard-ultrapro/:site_id/:company_id/:machine_id/:outdoorSensorId"
              element={
                <Suspense fallback={<></>}>
                  <Dashboard />
                </Suspense>
              }
            />
            <Route
              path="/select-station/:company_id"
              element={
                <Suspense fallback={<></>}>
                  <SelectStation />
                </Suspense>
              }
            />
            <Route
              path="/dashboard-ultimate/:site_id/:company_id/:machine_id/:outdoorSensorId"
              element={
                <Suspense fallback={<></>}>
                  <Dashboardaverage />
                </Suspense>
              }
            />
            <Route
              path="/dashboard-vanilla/:site_id/:company_id/:machine_id/:outdoorSensorId"
              element={
                <Suspense fallback={<></>}>
                   <Dashboardultimate />
                 
                </Suspense>
              }
            />
          </Route>
        </Routes>
      </div>
    </>
  );
};

export default Navigation;
